@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-800;
  }
  button,
  input,
  textarea,
  select {
    @apply focus:outline-none;
  }
}

@layer components {
  .avatar {
    @apply inline-block object-cover rounded-full;
  }
  .avatar-default {
    @apply inline-block align-middle rounded-full overflow-hidden bg-gray-100;
  }
  .button {
    @apply px-4 h-12 rounded-lg font-medium text-sm;
  }
  .card {
    @apply flex flex-col rounded-lg border border-gray-200 divide-y divide-gray-200 overflow-hidden shadow;
  }
  .checkbox {
    @apply wh-4 rounded text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .dropdown {
    @apply relative w-max;
  }
  .dropdown-view {
    @apply absolute mt-2 w-max shadow-lg ring-1 ring-black ring-opacity-5 bg-white z-10
           focus:outline-none transition transform origin-top;
  }
  .label {
    @apply text-sm text-gray-800;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }
  .menu {
    @apply flex flex-col py-1;
  }
  .menu-item {
    @apply px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800;
  }
  .pagination {
    @apply flex justify-between items-center px-6 py-3;
  }
  .pagination-label {
    @apply text-sm text-gray-700;
  }
  .pagination-nav {
    @apply flex shadow-sm -space-x-px;
  }
  .pagination-nav-button {
    @apply grid place-items-center p-2 min-w-10 border text-sm font-medium;
  }
  .radio {
    @apply wh-4 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .select {
    @apply px-4 h-12 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .sidebar {
    @apply flex flex-col w-64 border-r border-gray-200;
  }
  .sidebar-title {
    @apply flex items-center px-4 h-16 font-bold text-2xl text-gray-800;
  }
  .sidebar-menu {
    @apply flex-grow flex flex-col space-y-1 p-2 overflow-y-auto;
  }
  .sidebar-menu-item {
    @apply p-2 rounded-md text-sm font-medium flex items-center;
  }
  .table {
    @apply divide-y divide-gray-200;
  }
  .table-head {
    @apply bg-gray-50;
  }
  .table-body {
    @apply divide-y divide-gray-200;
  }
  .table-th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }
  .table-td {
    @apply px-6 py-4 max-w-xs text-left text-sm text-gray-500 truncate;
  }
  .textarea {
    @apply px-4 py-2 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .textfield {
    @apply px-4 h-12 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .toggle {
    @apply relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full
           cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1
           transition-colors ease-in-out duration-200;
  }
  .toggle-slider {
    @apply pointer-events-none inline-block w-5 h-5 rounded-full bg-white shadow ring-0
           transition ease-in-out duration-200 transform;
  }
}

.h1 {
  @apply text-24 font-medium;
}

.h2 {
  @apply text-20 font-medium;
}

.h3 {
  @apply text-18 font-medium;
}

.dots-css {
  bottom: 5px;
  width: 100%;
  list-style: none;
  text-align: center;
  position: absolute;
  padding: 0;
}

.dots-css li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  /* margin: 0 5px; */
  padding: 0;
  cursor: pointer;
}

.dots-css li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  /* padding: 5px; */
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.dots-css li button:before {
  font-size: 1.6rem;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
}

.dots-css li.slick-active button:before {
  opacity: 0.75;
  color: #000000;
}
